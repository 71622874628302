exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-projects-benalcons-js": () => import("./../../../src/pages/projects/projectsBenalcons.js" /* webpackChunkName: "component---src-pages-projects-projects-benalcons-js" */),
  "component---src-pages-projects-projects-emabesa-js": () => import("./../../../src/pages/projects/projectsEmabesa.js" /* webpackChunkName: "component---src-pages-projects-projects-emabesa-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

